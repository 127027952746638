import {isInteger} from './boolean';

/**
 * Converts the provided numeric string in to number.
 *
 * @param {string} val
 * @param {int} radix
 *
 * @returns {int}
 */
export const toNumber = (val, radix = 10) =>
  isInteger(val) ? parseInt(val, radix) : false;

/**
 * Converts the provided numeric string in to float number.
 *
 * @param {string} val
 * @param {int} decimals
 *
 * @returns {int}
 */
export const toFloat = (val, decimals) => {
  if (isInteger(val)) {
    if (decimals) {
      return parseFloat(val).toFixed(decimals);
    }
    return parseFloat(val);
  }
  return false;
};
