import React from 'react';
import NotFound from './NotFound';
import {StyledMessage} from './ListingErrorMessage.styled';

function ListingErrorMessage() {
  return (
    <NotFound
      heading=""
      text={
        <StyledMessage>
          Sorry, no properties have been found.
          <br />
          Please contact Tenant.co.nz on 0800 483 626 or email info@tenant.co.nz
          for help.
        </StyledMessage>
      }
    />
  );
}

export default ListingErrorMessage;
