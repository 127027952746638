import React from 'react';
import {Box, Button} from '@tenancy.nz/design-system';

function PropertyItemFooter() {
  return (
    <Box
      sx={{
        display: {
          xs: 'block',
          md: 'inline-flex',
        },
        width: {
          xs: '100%',
          md: 'auto',
        },
      }}>
      <Button type="button" color="primary" fill size="small">
        Book a viewing
      </Button>
    </Box>
  );
}

export default PropertyItemFooter;
