import React from 'react';
import {Alert} from '@tenancy.nz/design-system';

function MessageNoViewing() {
  return (
    <Alert variant="standard">
      There are currently no viewing times available for this property. Please
      register below and we will be in touch.
    </Alert>
  );
}

export default MessageNoViewing;
