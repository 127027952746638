class ValidationError extends Error {
  constructor(errors, message = 'A validation error occurred') {
    super(message);
    this.name = this.constructor.name;
    this.errors = errors;

    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error(message).stack;
    }
  }

  getErrors = () => {
    const {errors} = this;
    Object.keys(errors).forEach(key => {
      // eslint-disable-next-line fp/no-mutation
      errors[key] = Array.isArray(errors[key]) ? errors[key][0] : errors[key];
    });
    return errors;
  };
}

export default ValidationError;
