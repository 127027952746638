import React from 'react';
import PropTypes from 'prop-types';
import {
  Animate,
  Box,
  Container,
  Paper,
  Heading,
  Text,
} from '@tenancy.nz/design-system';

function EnquirySuccess({address}) {
  return (
    <Animate>
      <Box marginTop="70px">
        <Container maxWidth="sm">
          <Paper>
            <Box margin="30px">
              <Heading as="h1" align="center" color="inherit">
                Enquiry Successful
              </Heading>
              <Text align="center" color="inherit" variant="subtitle1">
                Your enquiry {(address && `for `) || ``}{' '}
                <strong>{address}</strong> has been submitted and the property
                manager will be in touch.
              </Text>
            </Box>
          </Paper>
        </Container>
      </Box>
    </Animate>
  );
}

EnquirySuccess.propTypes = {
  /**
   * Full property address
   */
  address: PropTypes.string,
};

EnquirySuccess.defaultProps = {
  address: undefined,
};

export default EnquirySuccess;
