import {isString} from './boolean';

export const redirect = url => {
  if (url && isString(url)) {
    // eslint-disable-next-line fp/no-mutation
    window.location.href = url;
    return true;
  }
  return false;
};
