import axios from 'axios';
import Service from '../../api/Service';
import TrackerService from '../../api/TrackerService';
import * as consts from '../../utils/constants/url';
/**
 * Makes an api call to create new viewing booking for a property
 *
 * @param {object} payload
 * @returns {Promise}
 */
export const createViewingBooking = async payload =>
  TrackerService.post(consts.API_PROPERTY_VIEWING_BOOKINGS, payload);

/**
 * Makes an api call to create new viewing enquiry for a property
 *
 * @param {object} payload
 * @returns {Promise}
 */
export const createViewingEnquiry = async payload =>
  TrackerService.post(consts.API_PROPERTY_VIEWING_ENQUIRIES, payload);

/**
 * Makes an api call to get property details and vieiwngs list.
 *
 * @param {int} id
 * @returns {Promise}
 */
export const showPropertyDetails = async id =>
  axios.all([
    Service.get(`${consts.API_PROPERTY_DETAILS}/${id}`),
    TrackerService.get(
      `${consts.API_PROPERTY_VIEWINGS}/${id}?${consts.API_PARAM_ATTENDEE_COUNT}=1&${consts.API_PARAM_BOOKING_COUNT}=1&${consts.API_PARAM_SORT}=date_time`
    ),
  ]);
