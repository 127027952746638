import React, {useEffect, useState} from 'react';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import {Animate, Nameplate} from '@tenancy.nz/design-system';
import {FORM_ERROR} from 'final-form';
import {GACreateBooking, GACreateEnquiry, pageView} from '../../analytics';
import BookingForm from '../../components/BookingForm';
import BookingSuccess from '../../components/BookingSuccess';
import EnquirySuccess from '../../components/EnquirySuccess';
import NotFound from '../../components/NotFound';
import DeletedProperty from '../../components/DeletedProperty';
import Footer from '../../components/Footer';
import {
  createViewingBooking,
  createViewingEnquiry,
  showPropertyDetails,
} from './propertySlice';
import * as consts from '../../utils/constants';
import {
  agencyHasAccess,
  env,
  handleBookingSubmitErrors,
  handleEnquirySubmitErrors,
  prepareBookingPayload,
  prepareEnquiryPayload,
  prepareMobileNumber,
  prepareViewingPropertyProps,
  redirect,
  setBookingDetailsCookie,
  toNumber,
} from '../../utils/helpers';

function PropertyFeature() {
  const {propertyId} = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState({});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [submitted, setSubmitted] = useState(false);

  const enquiryToken = searchParams.get(consts.URL_PARAM_ENQUIRY_TOKEN);
  const submitType = searchParams.get(consts.URL_PARAM_VIEWING);
  useEffect(() => {
    if (!submitted && !!submitType) {
      searchParams.delete(consts.URL_PARAM_VIEWING);
      setSearchParams(searchParams);
    }

    // Log the page view in analytics
    pageView({
      path: location.pathname,
      title: document.title,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    showPropertyDetails(propertyId)
      .then(response => {
        if (agencyHasAccess(response[0].data)) {
          setData(
            prepareViewingPropertyProps({
              property: response[0].data,
              viewings: response[1].data,
            })
          );
        } else {
          setError({message: 'Resource not found.', code: 404});
        }
        setLoading(false);
      })
      .catch(e => {
        setError(e);
      });
  }, [propertyId]);

  const analyticsData = {
    tps_referrer_code: searchParams.get('referrer'),
    tps_referrer_url: document.referrer ?? null,
  };

  const handleBookingSubmit = values => {
    try {
      // Do pre post validation
      const totalAttendees = toNumber(
        values[consts.FORM_FIELD_TOTAL_ATTENDEES]
      );
      const viewingId = toNumber(values[consts.FORM_FIELD_VIEWING_DAYS]);
      const selectedViewing = data.viewings.find(time => time.id === viewingId);
      const {
        totalBookings,
        totalBookingsAllowed,
        totalAllowed,
        maxViewersType,
        actualBookingCount,
      } = selectedViewing;
      const maxAttendeesAllowed = totalAllowed - totalBookings;

      // If the total bookings + 1 is greater than the allowed bookings
      if (
        maxViewersType !== 'MAX_ATTENDEES' &&
        actualBookingCount >= totalBookingsAllowed
      ) {
        return {
          [FORM_ERROR]: `Sorry, all ${totalBookingsAllowed} bookings have been filled on this property.`,
        };
      }

      //
      if (
        maxViewersType !== 'MAX_BOOKINGS' &&
        totalAttendees > maxAttendeesAllowed
      ) {
        return {
          [consts.FORM_FIELD_TOTAL_ATTENDEES]: `Sorry, only ${maxAttendeesAllowed} spaces available.`,
        };
      }

      const payload = {
        ...prepareBookingPayload(values),
        ...(!!enquiryToken && {
          [consts.BOOKING_ENQUIRY_TOKEN]: enquiryToken,
        }),
      };

      return createViewingBooking(payload)
        .then(response => {
          if (response.data.id) {
            setSubmitted(true);
            const tenantPortalUrl = env('REACT_APP_TENANT_PORTAL_URL');
            if (response.data.account === false && tenantPortalUrl) {
              setBookingDetailsCookie(response.data);
              redirect(`${tenantPortalUrl}/register`);
            } else {
              navigate(`?${consts.URL_PARAM_VIEWING}=1`);
            }

            // Log booking creation analytics
            GACreateBooking({
              ...analyticsData,
              viewingId,
              totalAttendees,
            });
          }
        })
        .catch(handleBookingSubmitErrors);
    } catch (err) {
      return {[FORM_ERROR]: 'Something went wrong'};
    }
  };

  const handleEnquirySubmit = values => {
    const payload = prepareEnquiryPayload({
      ...values,
      [consts.ENQUIRY_AGENCY_ID]: data.agencyId,
      [consts.ENQUIRY_FULL_ADDRESS]: data.address,
      [consts.ENQUIRY_PROPERTY_ID]: data.id,
    });
    return createViewingEnquiry(payload)
      .then(response => {
        if (response.data.id) {
          setSubmitted(true);
          navigate(`?${consts.URL_PARAM_VIEWING}=0`);

          // Log enquiry creation analytics
          GACreateEnquiry({
            ...analyticsData,
            propertyId: payload[consts.ENQUIRY_PROPERTY_ID],
            agencyId: payload[consts.ENQUIRY_AGENCY_ID],
          });
        }
      })
      .catch(handleEnquirySubmitErrors);
  };

  const handleSubmit = values => {
    const sanitizedValues = {
      ...values,
      [consts.FORM_FIELD_MOBILE]: prepareMobileNumber(
        values[consts.FORM_FIELD_MOBILE]
      ),
    };
    if (
      sanitizedValues[consts.FORM_FIELD_IS_ENQUIRY] === true ||
      sanitizedValues[consts.FORM_FIELD_IS_ENQUIRY] === '1'
    ) {
      return handleEnquirySubmit(sanitizedValues);
    }
    return handleBookingSubmit(sanitizedValues);
  };
  if (data.propertyStatus === 'deleted') {
    return (
      <DeletedProperty url={`${env('REACT_APP_URL')}/tps${data.clientCode}`} />
    );
  }
  return (
    <>
      {!error && (
        <>
          <Nameplate
            skeleton={loading}
            title="You are booking through:"
            heading={data.agencyName}
            subHeading={data.agencyCity}
            logo={data.agencyLogo}
          />
          <Animate>
            {submitted && submitType === '1' && (
              <BookingSuccess
                address={data.address}
                loginUrl={`${env('REACT_APP_TENANT_PORTAL_URL')}/login`}
                signUpUrl={`${env('REACT_APP_TENANT_PORTAL_URL')}/register`}
                viewingsUrl={`${env('REACT_APP_TENANT_PORTAL_URL')}/viewings`}
                subSectionHeading="Did you know you can manage your viewings through Tenant Portal?"
              />
            )}
            {submitted && submitType === '0' && (
              <EnquirySuccess address={data.address} />
            )}
            {(!submitted || !submitType) && (
              <BookingForm
                captcha={env('REACT_APP_GOOGLE_RECAPTCHA_ENABLED') === 'true'}
                heading={data.address}
                loading={loading}
                onSubmit={handleSubmit}
                supHeading="Viewing for"
                viewings={data.viewings}
                initialValues={{
                  [consts.FORM_FIELD_TERMS]: false,
                }}
              />
            )}
          </Animate>
        </>
      )}
      {error && error.code === 404 && <NotFound />}
      <Footer />
    </>
  );
}

export default PropertyFeature;
