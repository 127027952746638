import React from 'react';
import PropTypes from 'prop-types';
import {PropertyCard} from '@tenancy.nz/design-system';
import {StyledPropertyItem} from './PropertyItem.styled';

function PropertyItem({onClick, ...rest}) {
  return (
    <StyledPropertyItem>
      <PropertyCard onView={onClick} {...rest} />
    </StyledPropertyItem>
  );
}

PropertyItem.propTypes = {
  /**
   * View click handler
   */
  onClick: PropTypes.oneOfType([PropTypes.func, PropTypes.instanceOf(null)]),
};

PropertyItem.defaultProps = {
  onClick: null,
};

export default PropertyItem;
