import {getObjectProp} from './object';

/**
 * @param {object} data
 *
 * @return {boolean}
 */
export const agencyHasAccess = data =>
  getObjectProp(data.agency, 'has_viewme_access') === 'yes';

/**
 * @param {object} data
 *
 * @return {object}
 */
export const prepareAgencyDetails = data => ({
  id: getObjectProp(data.agency, 'id'),
  city: getObjectProp(data.agency, 'address_physical_city'),
  companyName: getObjectProp(data.agency, 'company_name_tenancy_application'),
  logo: getObjectProp(data.agency, 'logo'),
});
