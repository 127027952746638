/**
 *
 * @param {string} name - Name of the cookie
 * @param {string} value - Value of the cookie
 * @param {string} expiry - Expiry time in UTC string
 * @param {string} domain - Domain which cookie belongs to
 *
 * @return {void}
 */
export const setCookie = (name, value, expiry = '', domain = '') => {
  const cname = `${name}=${value};`;
  const cexpiry = expiry ? `expires=${expiry};` : ``;
  const cdomain = domain ? `domain=${domain};` : ``;
  // eslint-disable-next-line fp/no-mutation
  document.cookie = `${cname}${cexpiry}${cdomain}path=/`;
};
