import moment from 'moment';
import * as consts from '../constants/date';

/**
 * Check if the provided string is valid date
 *
 * @param {string} str
 *
 * @return {mixed}
 */
export const isDate = str => {
  if (str === 'Invalid date') {
    return false;
  }
  return str;
};

/**
 * Convert the provided date string to long dispaly format.
 *
 * @param {string} date
 * @param {string} fromFormat
 *
 * @return {mixed}
 */
export const dateToDisplayLongFormat = date => {
  const dateObj = moment(date).format(consts.DATE_FORMAT_DISPLAY_LONG);
  return isDate(dateObj);
};

/**
 * Convert the provided date string to `YYYY-MMM-DD` format.
 *
 * @param {string} date
 * @param {string} fromFormat
 *
 * @return {mixed}
 */
export const dateToSqlFormat = date => {
  const dateObj = moment(date).format(consts.DATE_FORMAT_SQL);
  return isDate(dateObj);
};

/**
 * Convert the provided date string to display time format.
 *
 * @param {string} date
 * @param {string} fromFormat
 *
 * @return {mixed}
 */
export const dateToDisplayTimeFormat = date => {
  const dateObj = moment(date).format(consts.TIME_FORMAT_DISPLAY);
  return isDate(dateObj);
};

/**
 * Convert the provided date string to long dispaly date and time.
 *
 * @param {string} date
 * @param {string} fromFormat
 *
 * @return {mixed}
 */
export const dateTimeToDisplayLongFormat = (date, fromFormat = null) => {
  const dateObj = moment(date, fromFormat).format(
    consts.DATE_TIME_FORMAT_DISPLAY_LONG
  );
  return isDate(dateObj);
};

/**
 * Converts UTC datetime to local formatted datetime.
 *
 * @param {string} date String in `YYYY-MM-DD HH:mm:ss` or ISO format.
 *
 * @return {string} datetime in `DD/MM/YYYY, hh:mm am|pm` format.
 */
export const formUtcToLocal = date => {
  const options = {
    timeZone: 'Pacific/Auckland',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  };
  return new Date(date).toLocaleString('en-NZ', options);
};
