import styled from 'styled-components';

export const StyledPropertyItem = styled.div`
  height: 100%;
  @media screen and (min-width: 768px) {
    transition: all 0.35s ease-in-out;
    backface-visibility: hidden;
    transform-origin: center;
    transform: translateZ(0) scale(1);

    &:hover {
      transform: translateZ(0) scale(1.04);
    }
  }
`;
