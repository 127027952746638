import React from 'react';
import PropTypes from 'prop-types';
import {Form, Field} from 'react-final-form';
import {
  Box,
  Button,
  Grid,
  Heading,
  Select,
  TextInput,
  useMediaQuery,
} from '@tenancy.nz/design-system';
import {getFieldProps} from '../utils/helpers';
import rentOptions from '../json/rent_options.json';
import bedroomOptions from '../json/bedroom_options.json';

function PropertyFilterForm({initialValues, onSubmit, onClear}) {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));

  const commonSelectProps = {
    size: 'small',
    native: isMobile,
    MenuProps: {
      style: {
        maxHeight: 350,
      },
    },
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({handleSubmit, form, pristine, submitting, values}) => (
        <form onSubmit={handleSubmit}>
          <Box>
            <Box margin="20px 0 30px">
              <Grid container spacing={4}>
                <Grid item container alignItems="center">
                  <Grid item xs={12} md={6}>
                    <Heading as="h3" gutterBottom={false}>
                      Browse Properties
                    </Heading>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field name="query">
                      {({input, meta}) => (
                        <TextInput
                          {...getFieldProps({input, meta})}
                          placeholder="Search property address"
                          edges="rounded"
                          fullWidth
                          autoComplete="off"
                        />
                      )}
                    </Field>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Box margin="30px 0 -30px 0">
              <Grid container spacing={4} alignItems="flex-end">
                <Grid item container xs={12} md={3}>
                  <Grid item xs={12}>
                    Rent
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                    <Field name="rent_from">
                      {({input, meta}) => (
                        <Select
                          {...getFieldProps({input, meta})}
                          placeholder="Any"
                          options={rentOptions}
                          {...commonSelectProps}
                        />
                      )}
                    </Field>
                    <Box display="inline-block" margin="0 5px">
                      -
                    </Box>
                    <Field name="rent_to">
                      {({input, meta}) => (
                        <Select
                          {...getFieldProps({input, meta})}
                          hideLabel
                          placeholder="Any"
                          options={rentOptions}
                          {...commonSelectProps}
                        />
                      )}
                    </Field>
                    <Box
                      display={isMobile ? 'none' : 'inline-block'}
                      margin="0 5px">
                      &nbsp;
                    </Box>
                  </Grid>
                </Grid>
                <Grid item container xs={12} md={3}>
                  <Grid item xs={12}>
                    Bedrooms
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                    <Field name="bedrooms_from">
                      {({input, meta}) => (
                        <Select
                          {...getFieldProps({input, meta})}
                          hideLabel
                          placeholder="Any"
                          options={bedroomOptions}
                          {...commonSelectProps}
                        />
                      )}
                    </Field>
                    <Box display="inline-block" margin="0 5px">
                      -
                    </Box>
                    <Field name="bedrooms_to">
                      {({input, meta}) => (
                        <Select
                          {...getFieldProps({input, meta})}
                          hideLabel
                          placeholder="Any"
                          options={bedroomOptions}
                          {...commonSelectProps}
                        />
                      )}
                    </Field>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Button
                    color="primary"
                    size={isMobile ? 'medium' : 'small'}
                    fill={isMobile}
                    loading
                    pending={submitting}
                    disabled={pristine || submitting}
                    type="submit">
                    Search
                  </Button>
                  <Box
                    as="span"
                    display="inline-flex"
                    sx={{
                      margin: {
                        xs: '5px 0',
                        md: '0 5px',
                      },
                    }}>
                    &nbsp;
                  </Box>
                  <Button
                    color="primary"
                    variant="outlined"
                    size={isMobile ? 'medium' : 'small'}
                    fill={isMobile}
                    disabled={Object.keys(values).length === 0}
                    onClick={() => {
                      form.reset();
                      if (onClear) {
                        onClear();
                      }
                    }}>
                    Clear
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={2}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                  }}>
                  &nbsp;
                </Grid>
              </Grid>
            </Box>
          </Box>
        </form>
      )}
    />
  );
}

PropertyFilterForm.propTypes = {
  /**
   *
   */
  initialValues: PropTypes.object,

  /**
   *
   */
  onSubmit: PropTypes.func.isRequired,

  /**
   *
   */
  onClear: PropTypes.func,
};

PropertyFilterForm.defaultProps = {
  initialValues: {},
  onClear: undefined,
};

export default PropertyFilterForm;
