import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Container,
  Box,
  Heading,
  Link,
  makeStyles,
} from '@tenancy.nz/design-system';
import {DEFAULT_404_IMG} from '../utils/constants';

const useStyles = makeStyles(() => ({
  fourohfour: {
    fontSize: 78,
  },
  image: {
    width: '60%',
    maxWidth: 250,
    display: 'block',
    height: 'auto',
    margin: '0 auto',
  },
  inherit: {
    fontWeight: 'inherit !important',
  },
}));

const DeletedProperty = ({url}) => {
  const classes = useStyles();
  return (
    <Container maxWidth="md">
      <Box
        sx={{
          p: 5,
        }}>
        <Grid container alignItems="center">
          <Grid item xs={12} md={7}>
            <Box
              sx={{
                py: 5,
              }}>
              <Heading as="h4" style={{lineHeight: 1.4}} align="center">
                The property has been removed from consideration. We encourage
                you to submit a booking for an alternative property.
              </Heading>
              <Heading as="h4" style={{lineHeight: 1.4}} align="center">
                Click{' '}
                <Link
                  href={url}
                  color="inherit"
                  target="_blank"
                  className={classes.inherit}
                  underline="always">
                  here
                </Link>{' '}
                to explore other available properties.
              </Heading>
            </Box>
          </Grid>
          <Grid item xs={12} md={5}>
            <Box
              sx={{
                py: 5,
              }}>
              <img alt="" src={DEFAULT_404_IMG} className={classes.image} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

DeletedProperty.propTypes = {
  url: PropTypes.string,
};

DeletedProperty.defaultProps = {
  url: null,
};

export default DeletedProperty;
