import React from 'react';
import {Box, Container, Link, Text} from '@tenancy.nz/design-system';

function Footer() {
  return (
    <Box
      padding="25px 0"
      borderTop="1px solid rgba(22, 34, 62, 0.4)"
      position="absolute"
      bottom="0"
      left="0"
      width="100%"
      bgcolor="#fff">
      <Container maxWidth="md">
        <Text>
          BookMe provided by{' '}
          <Link
            href="https://www.tenant.co.nz"
            rel="noopener noreferrer"
            target="_blank"
            underline="hover"
            color="secondary">
            www.tenant.co.nz
          </Link>{' '}
          Copyright &copy; {new Date().getFullYear()}
        </Text>
        <Text>
          To view our privacy policy and see how we protect your data,{' '}
          <Link
            rel="noopener noreferrer"
            href="https://tenant.co.nz/privacy-policy/"
            target="_blank"
            underline="hover">
            click here
          </Link>
        </Text>
      </Container>
    </Box>
  );
}

export default Footer;
