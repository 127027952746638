import React from 'react';
import ReactDOM from 'react-dom';
import {FlagsProvider} from 'react-unleash-flags';
import {ThemeProvider} from '@tenancy.nz/design-system';
import theme from './theme';
import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <FlagsProvider>
        <App />
      </FlagsProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
