import ReactGA from 'react-ga4';
import {env} from 'utils/helpers';

export const pageView = (data = {}) =>
  ReactGA.send({
    hitType: 'pageview',
    ...data,
  });

export const initGA = () =>
  ReactGA.initialize(env('REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID'));
