import {prepareAgencyDetails} from './agency';
import {isArray, isEmpty} from './boolean';
import {formUtcToLocal, dateTimeToDisplayLongFormat} from './date';
import {toNumber} from './number';
import {getObjectProp} from './object';
import {toString} from './string';

const filterNumberBetween = (num, from, to) => {
  if (num < 0) {
    return false;
  }
  if (from && to) {
    return num >= from && num <= to;
  }
  if (from && !to) {
    return num >= from;
  }
  if (!from && to) {
    return num <= to;
  }
  return true;
};

export const flattenPropertyViewingsToLocal = dates => {
  const values = Object.values(dates);
  const flattenedValues = [].concat(...values);
  return flattenedValues.map(value => ({
    dateTime: value.date_time,
    id: value.id,
    localDateTime: formUtcToLocal(value.date_time),
    totalAllowed: toNumber(value.total_allowed),
    totalBookingsAllowed: toNumber(value.total_bookings_allowed),
    totalBookings: toNumber(value.attending_count),
    actualBookingCount: toNumber(value.booking_count),
    maxViewersType: value.viewing_max_viewers_type,
  }));
};

export const preparePropertyViewings = viewings =>
  viewings
    .map(viewing => ({
      ...viewing,
      label: dateTimeToDisplayLongFormat(
        viewing.localDateTime,
        'DD/MM/YYYY, h:mm:ss a'
      ),
      value: toString(viewing.id),
    }))
    .filter(viewing => {
      let allowed = true;

      // Allow fall through for maxViewersType === MAX_ATTENDEES_AND_BOOKINGS

      if (viewing.maxViewersType !== 'MAX_ATTENDEES') {
        // eslint-disable-next-line fp/no-mutation
        allowed =
          allowed && viewing.actualBookingCount < viewing.totalBookingsAllowed;
      }

      if (viewing.maxViewersType !== 'MAX_BOOKINGS') {
        // eslint-disable-next-line fp/no-mutation
        allowed = allowed && viewing.totalBookings < viewing.totalAllowed;
      }

      return allowed;
    });

export const prepareViewingPropertyProps = ({property, viewings}) => {
  const agencyDetails = prepareAgencyDetails(property);
  const formattedViewings = flattenPropertyViewingsToLocal(viewings);
  return {
    address: getObjectProp(property.property, 'address'),
    agencyId: agencyDetails.id,
    agencyName: agencyDetails.companyName,
    agencyCity: agencyDetails.city,
    agencyLogo: agencyDetails.logo,
    id: getObjectProp(property.property, 'id'),
    viewings: preparePropertyViewings(formattedViewings),
    propertyStatus: getObjectProp(property.property, 'status'),
    clientCode: getObjectProp(property.property, 'client_code'),
  };
};

export const preparePropertyProps = property => {
  const images = getObjectProp(property, 'images') || [];
  return {
    address: getObjectProp(property, 'address'),
    id: getObjectProp(property, 'id'),
    unit: getObjectProp(property, 'unit'),
    streetNumber: getObjectProp(property, 'street_number'),
    streetName: getObjectProp(property, 'street_name'),
    suburb: getObjectProp(property, 'suburb'),
    city: getObjectProp(property, 'city'),
    photo: images.length > 0 ? images[0].medium_url : undefined,
    bedrooms: getObjectProp(property, 'num_bedrooms') || 0,
    bathrooms: getObjectProp(property, 'num_bathrooms') || 0,
    parkingSpaces: getObjectProp(property, 'num_cars') || 0,
    rentAmount: toNumber(getObjectProp(property, 'rent_amount')) || 0,
    rentFrequency: getObjectProp(property, 'rental_period'),
  };
};

export const preparePropertyListProps = items => {
  if (!isArray(items)) {
    return [];
  }
  return items.map(item => preparePropertyProps(item));
};

export const filterProperties = (items, filters) => {
  if (isEmpty(filters)) {
    return items;
  }
  const query = filters.query
    ? toString(filters.query).trim().toLowerCase()
    : '';
  return items.filter(
    item =>
      (query ? item.address.toLowerCase().includes(query) : true) &&
      filterNumberBetween(
        item.bedrooms,
        filters.bedrooms_from,
        filters.bedrooms_to
      ) &&
      filterNumberBetween(item.rentAmount, filters.rent_from, filters.rent_to)
  );
};
