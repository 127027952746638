import {isArray, isEmpty, isNotEmpty, isObject} from './boolean';

/**
 * Plucks specified props and its values from an object
 *
 * @param {object} object
 * @param {array} props
 *
 * @return {object}
 */
export const pick = (object, props) => {
  if (!isObject(object)) {
    // eslint-disable-next-line no-console
    console.error(`first argument should be an object.`);
    return false;
  }
  if (!isArray(props)) {
    // eslint-disable-next-line no-console
    console.error(`second argument should be an array.`);
    return false;
  }
  return props.reduce((prevVal, curVal) => {
    // eslint-disable-next-line no-prototype-builtins
    if (object.hasOwnProperty(curVal)) {
      return {
        ...prevVal,
        [curVal]: object[curVal],
      };
    }
    return prevVal;
  }, {});
};

/**
 * Converts array to an object.
 *
 * If `prop` param is provided, the key of the object will be the value of that prop
 *
 * @param {array} arr
 * @param {string} prop
 *
 * @return {object}
 */
export const transformArrToObject = (arr, prop) => {
  if (prop) {
    return arr.reduce(
      (obj, item) => ({
        ...obj,
        [item[prop]]: item,
      }),
      {}
    );
  }
  return {...arr};
};

/**
 * Checks if values of all props are not empty
 *
 * @param {object} object
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/every
 *
 * @return {boolean}
 */
export const objectHasAllValues = obj => {
  if (isEmpty(obj)) {
    return false;
  }
  return Object.values(obj).every(isNotEmpty);
};

/**
 * Checks if atleast one prop have value
 *
 * @param {object} object
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/some
 *
 * @return {boolean}
 */
export const objectHasSomeValues = obj => Object.values(obj).some(isNotEmpty);

/**
 * Gets the value of prop of provided object. Empty if not found
 *
 * @param {object} obj
 * @param {string} prop
 *
 * @return {mixed}
 */
export const getObjectProp = (obj, prop) => (isObject(obj) && obj[prop]) || '';
