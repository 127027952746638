/**
 * Human readable date format.
 *
 * @type {string}
 */
export const DATE_FORMAT_DISPLAY = 'DD/MM/YYYY';

/**
 * Human readable date long format.
 *
 * @type {string}
 */
export const DATE_FORMAT_DISPLAY_LONG = 'dddd, D MMMM';

/**
 * Format in which(most) api calls will return dates.
 *
 * @type {string}
 */
export const DATE_FORMAT_ISO8601 = 'YYYY-MM-DD HH:mm:ss';

/**
 * Format in which(most) api calls will return dates.
 *
 * @type {string}
 */
export const DATE_FORMAT_SQL = 'YYYY-MM-DD';

/**
 * Human readable time format.
 *
 * @type {string}
 */
export const TIME_FORMAT_DISPLAY = 'h:mma';

/**
 * Human readable date time long format.
 *
 * @type {string}
 */
export const DATE_TIME_FORMAT_DISPLAY_LONG = `${DATE_FORMAT_DISPLAY_LONG}, ${TIME_FORMAT_DISPLAY}`;
