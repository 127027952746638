import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {Animate, Box, Grid, Heading} from '@tenancy.nz/design-system';
import {DEFAULT_404_IMG} from '../utils/constants';

const StyledNotFoundHeading = styled.span`
  @media screen and (min-width: 992px) {
    font-size: 78px;
  }
`;

const StyledNotFoundImg = styled.div`
  max-width: 150px;
  img {
    width: 100%;
  }
`;

function NotFound({heading, text, img}) {
  return (
    <Box display="flex" justify="center" align="center" height="80vh">
      <Animate>
        <Box maxWidth="620px" width="100%">
          <Grid container alignItems="center">
            <Grid item xs={9}>
              <Heading as="h1" align="center">
                <StyledNotFoundHeading>{heading}</StyledNotFoundHeading>
              </Heading>
              <Heading as="h3" align="center">
                {text}
              </Heading>
            </Grid>
            <Grid item xs={3}>
              <StyledNotFoundImg>
                <img src={img} alt={heading} />
              </StyledNotFoundImg>
            </Grid>
          </Grid>
        </Box>
      </Animate>
    </Box>
  );
}

NotFound.propTypes = {
  /**
   * Main heading text to display
   */
  heading: PropTypes.string,

  /**
   * Message/Text to display
   */
  text: PropTypes.node,

  /**
   * Path to the image which needs to be displayed
   */
  img: PropTypes.string,
};

NotFound.defaultProps = {
  heading: '404',
  text: "Sorry, we can't seem to find the page you are looking for.",
  img: DEFAULT_404_IMG,
};

export default NotFound;
