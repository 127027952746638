/**
 * @type {string}
 */
export const BOOKING_COMMENTS = 'tenant_comment';

/**
 * @type {string}
 */
export const BOOKING_EMAIL = 'attendee_email';

/**
 * @type {string}
 */
export const BOOKING_FIRST_NAME = 'attendee_first_name';

/**
 * @type {string}
 */
export const BOOKING_LAST_NAME = 'attendee_last_name';

/**
 * @type {string}
 */
export const BOOKING_PHONE = 'attendee_phone';

/**
 * @type {string}
 */
export const BOOKING_TOTAL_ATTENDEES = 'total_attendees';

/**
 * @type {string}
 */
export const BOOKING_VIEWING_ID = 'viewing_id';

/**
 * @type {string}
 */
export const BOOKING_RECAPTCHA = 'recaptcha';

/**
 * @type {string}
 */
export const BOOKING_NOTIFICATION_TYPE = 'notification_type';

/**
 * @type {string}
 */
export const BOOKING_ENQUIRY_TOKEN = 'token';
