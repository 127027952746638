/* eslint-disable fp/no-mutating-methods */
// accept 0 or false as a valid value, but not undefined...
// eslint-disable-next-line no-unused-vars
import {isArray, isInteger, isString} from './boolean';

export const isRequired = value => {
  if (typeof value === 'string') {
    if (value.replace(/\s/g, '') === '') {
      return 'This field is required.';
    }
  }

  if (typeof value === 'number') {
    if (value.toString().replace(/\s/g, '') === '') {
      return 'This field is required.';
    }
  }

  return value !== undefined && value !== null && value !== ''
    ? undefined
    : 'This field is required.';
};

export const isRequiredBool = value =>
  value === true ? undefined : 'This field is required.';

export const getFieldProps = ({
  input,
  meta: {error, submitError, touched},
}) => ({
  ...input,
  errorMessage: error || submitError,
  showError: (error && touched) || !!submitError,
});

// won't except 0 or false..
export const requiredStrict = value =>
  value ? undefined : 'This field is required.';

export const isEmailAddress = value => {
  if (value) {
    const emailPattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailPattern.test(value.toLowerCase())
      ? undefined
      : 'Please enter a valid email address';
  }
  return undefined;
};

export const isUrl = value => {
  if (value) {
    const urlPattern =
      /(http|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:/~+#-]*[\w@?^=%&amp;/~+#-])?/;
    return urlPattern.test(value.toLowerCase())
      ? undefined
      : 'Please enter a valid URL (e.g. https://www.example.com)';
  }
  return undefined;
};

export const requiredIf =
  (...fields) =>
  (value, allValues) =>
    fields.some(field =>
      field
        .split('.')
        .reduce((o, i) => (o !== undefined ? o[i] : false), allValues)
    ) && !value
      ? 'This field is required'
      : undefined;

export const mustProvideAtLeastOne =
  (field, message = 'Please check at least one') =>
  (_, allValues) => {
    const result = field
      .split('.')
      .reduce((o, i) => (o !== undefined ? o[i] : false), allValues);
    return isArray(result) && result.length > 0 ? undefined : message;
  };

export const mustProvideOneOf =
  (fields, message = 'Please check at least one option') =>
  (_, allValues) =>
    fields.some(field =>
      field.split('.').reduce((o, i) => (o ? o[i] : false), allValues)
    )
      ? undefined
      : message;

export const password = value => {
  if (!value) {
    return 'Please enter a password';
  }

  const errors = [];
  // check length...
  if (value.length < 6 || value.length > 29) {
    errors.push('be 6-30 characters');
  }
  if (!value.match(/[a-z]/g)) {
    errors.push('include one lowercase letter');
  }
  if (!value.match(/[A-Z]/g)) {
    errors.push('include one uppercase letter');
  }
  if (
    !value.match(/[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/g) &&
    !value.match(/[0-9]/g)
  ) {
    errors.push('include a number/symbol');
  }

  if (errors.length > 0) {
    const lastError = errors.length > 1 ? `and ${errors.pop()}.` : '.';
    return `Password must ${errors.join(', ')} ${lastError}`;
  }

  return undefined;
};

export const mustBeNumber = value =>
  isNaN(parseInt(value, 10)) ? 'Must be a number' : undefined;

export const composeValidators =
  (...validators) =>
  (value, allValues) =>
    validators.reduce(
      (error, validator) => error || validator(value, allValues),
      undefined
    );

export const identity = value => value;

export const parseBool = value => parseInt(value, 10) === 1 || value === true;

export const validateResidentAge = value =>
  ['0-3', '4-10', '11-18', 'Adult', 'Elderly'].includes(value)
    ? undefined
    : 'This field is required.';

export const validateResidentRelationship = value =>
  ['Family', 'Friend'].includes(value) ? undefined : 'This field is required.';

export const validateBankAccount = value => {
  const parts = ['bank', 'branch', 'account', 'suffix'];

  const hasSomeValue = parts.reduce((prev, curr) => {
    if (value && value[curr]) {
      return true;
    }
    return prev;
  }, false);

  if (!hasSomeValue) {
    return 'Please provide your bank account number.';
  }

  const valueToStr = value
    ? `${value.bank}-${value.branch}-${value.account}-${value.suffix}`
    : ``;

  if (!/^\d{2}-\d{4}-\d{7}-(\d{3}|\d{2})$/.test(valueToStr)) {
    return 'Please provide a valid bank account number.';
  }

  return undefined;
};

export const validateTenancyBondAmount = value => {
  if (!value || value < 0) {
    return 'Bond amount of $0 or more is required.';
  }
  if (!isInteger(value)) {
    return 'Bond amount should be a valid number.';
  }
  return undefined;
};

export const requiredArray = value => {
  if (!isArray(value)) {
    return 'This field is required.';
  }
  if (value.length === 0) {
    return 'This field is required.';
  }
  return undefined;
};

export const parseToLowerCase = value => {
  if (value && isString(value)) {
    return value.toLowerCase();
  }
  return value;
};
