import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {uid} from 'react-uid';
import {Box, Checkbox, FormControl} from '@tenancy.nz/design-system';

function CheckboxGroup({
  disabled,
  errorMessage,
  label,
  name,
  options,
  onChange,
  required,
  showError,
  value,
}) {
  const [selection, setSelection] = useState(value || '');

  const handleChange = e => {
    setSelection(e.target.value);
    onChange(e.target.value);
  };

  return (
    <FormControl
      errorMessage={errorMessage}
      fullWidth
      label={label}
      required={required}
      name={name}
      showError={showError}
      disabled={disabled}>
      <Box margin="10px 0">
        {options.map(option => (
          <Box key={uid(option)} margin="8px 0">
            <Checkbox
              checked={selection === option.value}
              disabled={disabled}
              filledOnChecked
              label={option.label}
              name={name}
              onChange={handleChange}
              value={option.value}
            />
          </Box>
        ))}
      </Box>
    </FormControl>
  );
}

CheckboxGroup.propTypes = {
  /**
   * If `true`, will make fields unselectable.
   */
  disabled: PropTypes.bool,

  /**
   * Text represents the error message.
   */
  errorMessage: PropTypes.string,

  /**
   * Text to display as lable for the group
   */
  label: PropTypes.string.isRequired,

  /**
   * HTML attribute of checkobx element.
   */
  name: PropTypes.string.isRequired,

  /**
   * List of options to render as list.
   */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,

  /**
   * On change event handler.
   */
  onChange: PropTypes.func.isRequired,

  /**
   * If `true`,  will be marked as required.
   */
  required: PropTypes.bool,

  /**
   * If `true`,  will show error message.
   */
  showError: PropTypes.bool,

  /**
   * A selected value.
   */
  value: PropTypes.string,
};

CheckboxGroup.defaultProps = {
  disabled: false,
  errorMessage: '',
  required: false,
  showError: false,
  value: '',
};

export default CheckboxGroup;
