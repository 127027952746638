import {isEmpty, isArray} from './boolean';

export const implode = (delimiter, arr, noEmpty = true) =>
  (noEmpty ? arr.filter(item => !isEmpty(item)) : arr).join(delimiter);

export const range = (start, end) =>
  Array.from({length: end - start}, (v, k) => k + start);

export const arrayUnique = arr => {
  if (!isArray(arr)) {
    // eslint-disable-next-line no-console
    console.error(`first argument should be an array.`);
    return false;
  }
  const iterator = (value, index, self) => self.indexOf(value) === index;
  return arr.filter(iterator);
};

/**
 *
 * @param {array} data
 * @returns {string}
 */
export const jsonEncode = data => JSON.stringify(data);

/**
 *
 * @param {string} str
 * @returns {array}
 */
export const jsonDecode = str => JSON.parse(str);
