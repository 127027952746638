import Service from '../../api/Service';
import * as consts from '../../utils/constants/url';

/**
 * Makes an api call to get ageny details and properties associated with the agency.
 *
 * @param {string} clientCode
 * @returns {Promise}
 */
export const showAgencyDetails = async clientCode =>
  Service.get(`${consts.API_PROPERTY_DETAILS}/tps${clientCode}`);
