import React from 'react';
import {Container, Heading} from '@tenancy.nz/design-system';

function Home() {
  return (
    <Container
      maxWidth="sm"
      style={{
        paddingTop: 50,
      }}>
      <Heading as="h1" align="center">
        Welcome to BookMe.
      </Heading>
    </Container>
  );
}

export default Home;
