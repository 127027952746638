import * as consts from '../constants';

export const handleEnquirySubmitErrors = err => {
  const errors = (err && err.getErrors && err.getErrors()) || {};
  const fieldsMap = {
    [consts.ENQUIRY_COMMENTS]: consts.FORM_FIELD_COMMENTS,
    [consts.ENQUIRY_EMAIL]: consts.FORM_FIELD_EMAIL,
    [consts.ENQUIRY_FIRST_NAME]: consts.FORM_FIELD_FIRST_NAME,
    [consts.ENQUIRY_LAST_NAME]: consts.FORM_FIELD_LAST_NAME,
    [consts.ENQUIRY_PHONE]: consts.FORM_FIELD_MOBILE,
    [consts.ENQUIRY_TOTAL_ATTENDEES]: consts.FORM_FIELD_TOTAL_ATTENDEES,
    [consts.ENQUIRY_RECAPTCHA]: consts.FORM_FIELD_RECAPTCHA,
  };
  return Object.keys(fieldsMap).reduce((values, field) => {
    if (errors[field]) {
      return {
        ...values,
        [fieldsMap[field]]: errors[field],
      };
    }
    return values;
  }, {});
};

export const prepareEnquiryPayload = values => {
  const toRemove = [
    consts.FORM_FIELD_IS_ENQUIRY,
    consts.FORM_FIELD_TERMS,
    consts.FORM_FIELD_VIEWING_DAYS,
  ];
  const fieldsMap = {
    [consts.FORM_FIELD_COMMENTS]: consts.ENQUIRY_COMMENTS,
    [consts.FORM_FIELD_EMAIL]: consts.ENQUIRY_EMAIL,
    [consts.FORM_FIELD_FIRST_NAME]: consts.ENQUIRY_FIRST_NAME,
    [consts.ENQUIRY_FULL_ADDRESS]: consts.ENQUIRY_FULL_ADDRESS,
    [consts.FORM_FIELD_LAST_NAME]: consts.ENQUIRY_LAST_NAME,
    [consts.FORM_FIELD_MOBILE]: consts.ENQUIRY_PHONE,
    [consts.FORM_FIELD_TOTAL_ATTENDEES]: consts.ENQUIRY_TOTAL_ATTENDEES,
    [consts.ENQUIRY_AGENCY_ID]: consts.ENQUIRY_AGENCY_ID,
    [consts.ENQUIRY_PROPERTY_ID]: consts.ENQUIRY_PROPERTY_ID,
    [consts.FORM_FIELD_RECAPTCHA]: consts.ENQUIRY_RECAPTCHA,
  };
  return Object.keys(values).reduce((fields, currentField) => {
    if (!toRemove.includes(currentField)) {
      return {
        ...fields,
        [fieldsMap[currentField]]: values[currentField],
      };
    }
    return fields;
  }, {});
};
