/**
 * @type {string}
 */
export const FORM_FIELD_COMMENTS = 'comment';

/**
 * @type {string}
 */
export const FORM_FIELD_EMAIL = 'email';

/**
 * @type {string}
 */
export const FORM_FIELD_FIRST_NAME = 'first_name';

/**
 * @type {string}
 */
export const FORM_FIELD_IS_ENQUIRY = 'is_enquiry';

/**
 * @type {string}
 */
export const FORM_FIELD_LAST_NAME = 'last_name';

/**
 * @type {string}
 */
export const FORM_FIELD_MOBILE = 'mobile';

/**
 * @type {string}
 */
export const FORM_FIELD_TOTAL_ATTENDEES = 'total_attendees';

/**
 * @type {string}
 */
export const FORM_FIELD_VIEWING_DAYS = 'viewing_days';

/**
 * @type {string}
 */
export const FORM_FIELD_TERMS = 'terms';

/**
 * @type {string}
 */
export const FORM_FIELD_RECAPTCHA = 'recaptcha';
