import axios from 'axios';
import ValidationError from '../Errors/ValidationError';
import UnauthorizedError from '../Errors/UnauthorizedError';
import {env} from '../utils/helpers';

export const baseUrl = `${env(
  'REACT_APP_BOOKME_API_BASE_URL',
  'https://tracker-api.tpsportal.co.nz'
)}/api/`;

const TrackerService = axios.create({
  baseURL: baseUrl,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    Accept: 'application/json',
  },
  withCredentials: true,
});

const handleSuccess = response => response.data;

const handleError = error => {
  const {response} = error;
  if (response) {
    // eslint-disable-next-line default-case
    switch (response.status) {
      case 422:
        return Promise.reject(
          new ValidationError(response.data.errors, response.data.message)
        );
      case 403:
      case 401:
      case 404:
        return Promise.reject(
          new UnauthorizedError(response.status, response.data.message)
        );
    }
  }
  return Promise.reject(
    new Error('Communications Error - Please try again later')
  );
};

TrackerService.interceptors.response.use(handleSuccess, handleError);

TrackerService.interceptors.request.use(
  config => config,
  error => {
    Promise.reject(error);
  }
);

export default TrackerService;
