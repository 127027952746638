import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import {Form, Field} from 'react-final-form';
import ReCAPTCHA from 'react-google-recaptcha';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  Heading,
  Link,
  Paper,
  Skeleton,
  Text,
  TextInput,
} from '@tenancy.nz/design-system';
import CheckboxField from './CheckboxField';
import CheckboxGroup from './CheckboxGroup';
import MessageNoViewing from './MessageNoViewing';
import * as consts from '../utils/constants/form';
import {
  composeValidators,
  env,
  getFieldProps,
  isEmailAddress,
  isRequired,
  isRequiredBool,
  parseToLowerCase,
} from '../utils/helpers';

function BookingForm({
  captcha,
  heading,
  initialValues,
  loading,
  onSubmit,
  supHeading,
  viewings,
}) {
  const reCaptchaRef = useRef();

  const handleFormSubmit = async (values, form, callback) => {
    if (reCaptchaRef.current.getValue()) {
      reCaptchaRef.current.reset();
    }
    const token = await reCaptchaRef.current.executeAsync();
    return onSubmit(
      {
        ...values,
        [consts.FORM_FIELD_RECAPTCHA]: token,
      },
      form,
      callback
    );
  };

  return (
    <Box margin="40px auto" width="100%" maxWidth="550px">
      <Box margin="0 15px">
        <Paper>
          <Box padding="20px">
            {!loading && (
              <Heading as="h2" gutterBottom>
                {supHeading && (
                  <Text
                    as="span"
                    variant="subtitle1"
                    color="textSecondary"
                    weight="700">
                    {supHeading}
                  </Text>
                )}
                {heading}
              </Heading>
            )}
            {loading && (
              <Box>
                <Skeleton width={120} height={25} />
                <Skeleton width="90%" height={25} />
                <Skeleton width="60%" height={25} />
              </Box>
            )}
            <Box marginTop="20px">
              <Form
                initialValues={initialValues}
                onSubmit={captcha ? handleFormSubmit : onSubmit}
                render={({
                  pristine,
                  handleSubmit,
                  submitting,
                  submitError,
                  values,
                  dirtyFieldsSinceLastSubmit,
                }) => (
                  <form onSubmit={handleSubmit}>
                    {submitError && (
                      <Box mb={5}>
                        <Alert variant="outlined" color="error">
                          {submitError}
                        </Alert>
                      </Box>
                    )}
                    <Grid container spacing={5}>
                      <Grid item xs={12}>
                        <Field
                          name={consts.FORM_FIELD_FIRST_NAME}
                          validate={isRequired}>
                          {({input, meta}) => (
                            <TextInput
                              label="First Name"
                              fullWidth
                              required
                              {...getFieldProps({input, meta})}
                            />
                          )}
                        </Field>
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          name={consts.FORM_FIELD_LAST_NAME}
                          validate={isRequired}>
                          {({input, meta}) => (
                            <TextInput
                              label="Last Name"
                              fullWidth
                              required
                              {...getFieldProps({input, meta})}
                            />
                          )}
                        </Field>
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          name={consts.FORM_FIELD_EMAIL}
                          parse={parseToLowerCase}
                          validate={composeValidators(
                            isRequired,
                            isEmailAddress
                          )}>
                          {({input, meta}) => (
                            <TextInput
                              label="Email"
                              fullWidth
                              type="email"
                              required
                              {...getFieldProps({input, meta})}
                            />
                          )}
                        </Field>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          name={consts.FORM_FIELD_MOBILE}
                          validate={isRequired}>
                          {({input, meta}) => (
                            <TextInput
                              label="Mobile"
                              fullWidth
                              type="tel"
                              required
                              InputProps={{
                                startAdornment: (
                                  <span style={{marginRight: -12}}>+64</span>
                                ),
                              }}
                              {...getFieldProps({input, meta})}
                            />
                          )}
                        </Field>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          name={consts.FORM_FIELD_TOTAL_ATTENDEES}
                          validate={isRequired}>
                          {({input, meta}) => (
                            <TextInput
                              label="Number of People Attending"
                              fullWidth
                              required
                              inputProps={{
                                inputMode: 'numeric',
                              }}
                              {...getFieldProps({
                                input,
                                meta: dirtyFieldsSinceLastSubmit[
                                  consts.FORM_FIELD_VIEWING_DAYS
                                ]
                                  ? {}
                                  : meta,
                              })}
                            />
                          )}
                        </Field>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider dense />
                      </Grid>
                      {!loading && viewings.length > 0 && (
                        <>
                          <Grid
                            item
                            xs={12}
                            style={{
                              opacity: values[consts.FORM_FIELD_IS_ENQUIRY]
                                ? 0.4
                                : 1,
                            }}>
                            <Field
                              name={consts.FORM_FIELD_VIEWING_DAYS}
                              key={`${
                                consts.FORM_FIELD_VIEWING_DAYS
                              }_${!!values[consts.FORM_FIELD_IS_ENQUIRY]}`}
                              validate={
                                values[consts.FORM_FIELD_IS_ENQUIRY]
                                  ? null
                                  : isRequired
                              }
                              type="checkbox">
                              {({input, meta}) => (
                                <CheckboxGroup
                                  label="Choose a Viewing Date"
                                  required={
                                    !values[consts.FORM_FIELD_IS_ENQUIRY]
                                  }
                                  disabled={
                                    !!values[consts.FORM_FIELD_IS_ENQUIRY]
                                  }
                                  options={viewings}
                                  {...getFieldProps({input, meta})}
                                  value={values[consts.FORM_FIELD_VIEWING_DAYS]}
                                />
                              )}
                            </Field>
                          </Grid>
                          <Grid item xs={12}>
                            <Field
                              name={consts.FORM_FIELD_IS_ENQUIRY}
                              type="checkbox">
                              {({input, meta}) => (
                                <Checkbox
                                  color="primary"
                                  filledOnChecked
                                  label="Viewing Times Not Suitable"
                                  {...getFieldProps({input, meta})}
                                />
                              )}
                            </Field>
                          </Grid>
                        </>
                      )}
                      {!loading && viewings.length === 0 && (
                        <Grid item xs={12}>
                          <MessageNoViewing />
                          <Field
                            name={consts.FORM_FIELD_IS_ENQUIRY}
                            defaultValue="1">
                            {({input}) => (
                              <input type="hidden" value={input.value} />
                            )}
                          </Field>
                        </Grid>
                      )}
                      {loading && (
                        <Grid item xs={12}>
                          <Skeleton height={30} width="55%" />
                          <Skeleton height={30} width="45%" />
                          <Skeleton height={30} width="55%" />
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <Field
                          name={consts.FORM_FIELD_COMMENTS}
                          key={`${consts.FORM_FIELD_COMMENTS}_${!!values[
                            consts.FORM_FIELD_IS_ENQUIRY
                          ]}`}
                          validate={
                            values[consts.FORM_FIELD_IS_ENQUIRY]
                              ? isRequired
                              : null
                          }>
                          {({input, meta}) => (
                            <TextInput
                              label="Comments / Questions"
                              fullWidth
                              multiline
                              required={!!values[consts.FORM_FIELD_IS_ENQUIRY]}
                              rows={6}
                              {...getFieldProps({input, meta})}
                            />
                          )}
                        </Field>
                      </Grid>
                      <Grid item xs={12}>
                        <CheckboxField
                          name={consts.FORM_FIELD_TERMS}
                          validate={isRequiredBool}
                          label={
                            <>
                              I agree to the{' '}
                              <Link
                                href="https://tenant.co.nz/privacy-policy"
                                target="_blank"
                                underline="hover"
                                rel="noopener noreferrer">
                                privacy consents
                              </Link>{' '}
                              and agree to receive text alerts relating to
                              property viewings.
                            </>
                          }
                        />
                      </Grid>
                      {captcha && (
                        <Grid item xs={12} style={{zIndex: 2}}>
                          <ReCAPTCHA
                            sitekey={env('REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY')}
                            ref={reCaptchaRef}
                            size="invisible"
                          />{' '}
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <Button
                          disabled={submitting || pristine}
                          type="submit"
                          size="large"
                          color="primary"
                          loading
                          fullWidth
                          pending={submitting || loading}>
                          {values[consts.FORM_FIELD_IS_ENQUIRY]
                            ? 'Submit'
                            : 'Book'}
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                )}
              />
            </Box>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
}

BookingForm.propTypes = {
  /**
   * If `true`, will enable google recaptcha
   */
  captcha: PropTypes.bool,

  /**
   * Form section heading.
   */
  heading: PropTypes.string,

  /**
   * Object of values which will be initial values of form fields.
   */
  initialValues: PropTypes.object,

  /**
   * If `true`, will show loading state
   */
  loading: PropTypes.bool,

  /**
   * Function to handle form submit action.
   */
  onSubmit: PropTypes.func.isRequired,

  /**
   * Text to display above the heading.
   */
  supHeading: PropTypes.string,

  /**
   * List of viewing days
   */
  viewings: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
};

BookingForm.defaultProps = {
  captcha: true,
  heading: undefined,
  initialValues: undefined,
  loading: false,
  supHeading: undefined,
  viewings: [],
};

export default BookingForm;
