import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'react-final-form';
import {Box, Checkbox, FormControl, Text} from '@tenancy.nz/design-system';
import {getFieldProps} from '../utils/helpers';

function CheckboxField({label, name, validate}) {
  return (
    <Field name={name} type="checkbox" validate={validate || null}>
      {({input, meta}) => (
        <FormControl name={name} {...getFieldProps({meta})}>
          <Box as="span" display="flex">
            <Checkbox
              color="primary"
              filledOnChecked
              id={name}
              label=""
              {...getFieldProps({input, meta})}
            />
            <Text
              as="span"
              color="textSecondary"
              style={{
                position: 'relative',
                left: -10,
                top: -2,
              }}>
              <label htmlFor={name} style={{cursor: 'pointer'}}>
                {label}
              </label>
            </Text>
          </Box>
        </FormControl>
      )}
    </Field>
  );
}

CheckboxField.propTypes = {
  /**
   *
   */
  label: PropTypes.node.isRequired,

  /**
   *
   */
  name: PropTypes.string.isRequired,

  /**
   *
   */
  validate: PropTypes.func,
};

CheckboxField.defaultProps = {
  validate: undefined,
};

export default CheckboxField;
