/**
 * @type {string}
 */
export const ENQUIRY_COMMENTS = 'text';

/**
 * @type {string}
 */
export const ENQUIRY_EMAIL = 'email';

/**
 * @type {string}
 */
export const ENQUIRY_FIRST_NAME = 'first_name';

/**
 * @type {string}
 */
export const ENQUIRY_FULL_ADDRESS = 'full_address';

/**
 * @type {string}
 */
export const ENQUIRY_LAST_NAME = 'last_name';

/**
 * @type {string}
 */
export const ENQUIRY_PHONE = 'phone';

/**
 * @type {string}
 */
export const ENQUIRY_TOTAL_ATTENDEES = 'total_attendees';

/**
 * @type {string}
 */
export const ENQUIRY_AGENCY_ID = 'agency_id';

/**
 * @type {string}
 */
export const ENQUIRY_PROPERTY_ID = 'property_id';

/**
 * @type {string}
 */
export const ENQUIRY_RECAPTCHA = 'recaptcha';
