import ReactGA from 'react-ga4';

export const eventName = 'BookMeEnquiry';
export const eventCategory = 'BookMe';
export const eventAction = 'Submit';
export const eventLabel = 'Tenant enquired about a property';

export default function createEnquiry(eventArgs = {}) {
  return ReactGA.event(eventName, {
    category: eventCategory,
    action: eventAction,
    label: eventLabel,
    transport: 'xhr',
    ...eventArgs,
  });
}
