/**
 * @type {string}
 */
export const API_PROPERTY_DETAILS = `/tenancy/apply`;

/**
 * @type {string}
 */
export const API_PROPERTY_VIEWINGS = `/property_viewings`;

/**
 * @type {string}
 */
export const API_PROPERTY_VIEWING_BOOKINGS = `/viewing_bookings`;

/**
 * @type {string}
 */
export const API_PROPERTY_VIEWING_ENQUIRIES = `/viewing_enquiries`;

/**
 * @type {string}
 */
export const API_PARAM_ATTENDEE_COUNT = `with_attending_count`;

/**
 * @type {string}
 */
export const API_PARAM_BOOKING_COUNT = `with_active_booking_count`;

/**
 * @type {string}
 */
export const API_PARAM_SORT = `sort`;

/**
 * @type {string}
 */
export const URL_PARAM_VIEWING = `viewing`;

/**
 * @type {string}
 */
export const URL_SEGMENT_SUCCESS = `success`;

/**
 * @type {string}
 */
export const URL_PARAM_ENQUIRY_TOKEN = 'i';
