/**
 *
 * @param {string} key
 * @param {mixed} value
 * @returns {mixed}
 */
export const setStorageItem = (key, value) => {
  if (key && value) {
    return localStorage.setItem(key, value);
  }
  return undefined;
};

/**
 *
 * @param {string} key
 * @returns {mixed}
 */
export const getStorageItem = key => {
  if (!key) {
    return undefined;
  }
  return localStorage.getItem(key);
};
