import React, {Suspense} from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import {AnimatePresence} from 'framer-motion';
import {useFlag} from 'react-unleash-flags';
import Home from './components/Home';
import Loader from './components/Loader';
import Maintenance from './components/Maintenance';
import NotFound from './components/NotFound';
import ListingFeature from './features/listing/ListingFeature';
import PropertyFeature from './features/property/PropertyFeature';
import * as consts from './utils/constants';
import {initGA} from './analytics';

initGA();

function App() {
  const maintenanceModeFlag = useFlag(consts.FEATURE_FLAG_MAINTENANCE_MODE);
  if (maintenanceModeFlag && maintenanceModeFlag.enabled === true) {
    return <Maintenance />;
  }

  return (
    <AnimatePresence>
      <Suspense fallback={<Loader />}>
        <BrowserRouter>
          <Routes>
            <Route index path="/" element={<Home />} />
            <Route path="tps:clientCode" element={<ListingFeature />} />
            <Route path=":propertyId" element={<PropertyFeature />} />
            <Route path=":propertyId/*" element={<NotFound />} />
            <Route path="/page-not-found" element={<NotFound />} />
            <Route path="/*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </Suspense>
    </AnimatePresence>
  );
}

export default App;
