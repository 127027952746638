import moment from 'moment';
import {setCookie} from './cookie';
import {env} from './general';
import {getObjectProp} from './object';
import * as consts from '../constants';
import {isObject} from './boolean';

export const handleBookingSubmitErrors = err => {
  const errors = (err && err.getErrors && err.getErrors()) || {};
  const fieldsMap = {
    [consts.BOOKING_COMMENTS]: consts.FORM_FIELD_COMMENTS,
    [consts.BOOKING_EMAIL]: consts.FORM_FIELD_EMAIL,
    [consts.BOOKING_FIRST_NAME]: consts.FORM_FIELD_FIRST_NAME,
    [consts.BOOKING_LAST_NAME]: consts.FORM_FIELD_LAST_NAME,
    [consts.BOOKING_PHONE]: consts.FORM_FIELD_MOBILE,
    [consts.BOOKING_TOTAL_ATTENDEES]: consts.FORM_FIELD_TOTAL_ATTENDEES,
    [consts.BOOKING_VIEWING_ID]: consts.FORM_FIELD_VIEWING_DAYS,
    [consts.BOOKING_RECAPTCHA]: consts.FORM_FIELD_RECAPTCHA,
  };
  return Object.keys(fieldsMap).reduce((values, field) => {
    if (errors[field]) {
      return {
        ...values,
        [fieldsMap[field]]: errors[field],
      };
    }
    return values;
  }, {});
};

export const prepareBookingPayload = values => {
  const toRemove = [
    consts.FORM_FIELD_IS_ENQUIRY,
    consts.FORM_FIELD_TERMS,
    consts.FORM_FIELD_VIEWING_DAYS,
  ];
  const fieldsMap = {
    [consts.FORM_FIELD_COMMENTS]: consts.BOOKING_COMMENTS,
    [consts.FORM_FIELD_EMAIL]: consts.BOOKING_EMAIL,
    [consts.FORM_FIELD_FIRST_NAME]: consts.BOOKING_FIRST_NAME,
    [consts.FORM_FIELD_LAST_NAME]: consts.BOOKING_LAST_NAME,
    [consts.FORM_FIELD_MOBILE]: consts.BOOKING_PHONE,
    [consts.FORM_FIELD_TOTAL_ATTENDEES]: consts.BOOKING_TOTAL_ATTENDEES,
    [consts.FORM_FIELD_RECAPTCHA]: consts.BOOKING_RECAPTCHA,
  };
  const payload = Object.keys(values).reduce((fields, currentField) => {
    if (!toRemove.includes(currentField)) {
      return {
        ...fields,
        [fieldsMap[currentField]]: values[currentField],
      };
    }
    return fields;
  }, {});
  return {
    ...payload,
    [consts.BOOKING_VIEWING_ID]: values[consts.FORM_FIELD_VIEWING_DAYS],
    [consts.BOOKING_NOTIFICATION_TYPE]: ['email'],
  };
};

export const setBookingDetailsCookie = data => {
  const sessionDomain = env('REACT_APP_SESSION_DOMAIN');
  if (sessionDomain && isObject(data)) {
    const expiry = moment().utc().add(10, 'minutes').toString();
    setCookie(
      'fname',
      getObjectProp(data, 'attendee_first_name'),
      expiry,
      sessionDomain
    );
    setCookie(
      'lname',
      getObjectProp(data, 'attendee_last_name'),
      expiry,
      sessionDomain
    );
    setCookie(
      'email',
      getObjectProp(data, 'attendee_email'),
      expiry,
      sessionDomain
    );
  }
};
