import React from 'react';
import styled from 'styled-components';
import {motion} from 'framer-motion';
import {CircularProgress} from '@tenancy.nz/design-system';

const StyledLoader = styled(motion.div)`
  position: fixed;
  left: 50%;
  top: 20%;
  transform: translateX(-50%);
  width: 70px;
  height: 70px;
  overflow: visible;
  border-radius: 50%;
  box-shadow: 0px 7px 17px 1px rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  z-index: 99999;
  background: #fff;
`;

const Loader = () => {
  const variants = {
    initial: {
      y: -20,
      opacity: 0,
    },
    in: {
      y: 0,
      opacity: 1,
    },
    out: {
      y: -20,
      opacity: 0,
    },
  };
  return (
    <StyledLoader
      variants={variants}
      animate="in"
      exit="out"
      initial="initial"
      transition={{duration: 0.25}}>
      <CircularProgress color="secondary" thickness={5} size={50} />
    </StyledLoader>
  );
};

export default Loader;
