import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {Container, Nameplate} from '@tenancy.nz/design-system';
import ListingErrorMessage from '../../components/ListingErrorMessage';
import PropertyFilterForm from '../../components/PropertyFilterForm';
import PropertyItem from '../../components/PropertyItem';
import PropertyItemFooter from '../../components/PropertyItemFooter';
import PropertyList from '../../components/PropertyList';
import Footer from '../../components/Footer';
import {showAgencyDetails} from './listingSlice';
import {
  filterProperties,
  getStorageItem,
  jsonDecode,
  jsonEncode,
  prepareAgencyDetails,
  preparePropertyListProps,
  setStorageItem,
  agencyHasAccess,
} from '../../utils/helpers';
import placeholderImg from '../../assets/placeholder-property-alt.png';

function ListingFeature() {
  const navigate = useNavigate();
  const {clientCode} = useParams();
  const [agency, setAgency] = useState({});
  const [error, setError] = useState(null);
  const [filters, setFilters] = useState(
    jsonDecode(getStorageItem('filters')) || {}
  );
  const [loading, setLoading] = useState(true);
  const [properties, setProperties] = useState([]);

  useEffect(() => {
    showAgencyDetails(clientCode)
      .then(response => {
        if (agencyHasAccess(response.data)) {
          setAgency(prepareAgencyDetails(response.data));
          setProperties(preparePropertyListProps(response.data.properties));
        } else {
          setError({message: 'Resource not found.', code: 404});
        }
        setLoading(false);
      })
      .catch(e => {
        setError(e);
      });
  }, [clientCode]);

  // Animate and scroll to the last scroll point. Typically occurs when back button is clicked.
  useEffect(() => {
    const interval = setTimeout(() => {
      const scrollPoint = window.history.state.lastScrollPos || 0;
      if (scrollPoint > 0 && !loading) {
        window.scrollBy({
          top: scrollPoint,
          behavior: 'smooth',
        });
        window.history.replaceState('lastScrollPos', 0);
      }
    }, 300);
    return () => clearTimeout(interval);
  }, [properties, loading]);

  const handleFilterSubmit = values => {
    setStorageItem('filters', jsonEncode(values));
    setFilters(values);
  };

  const handleFilterClear = () => {
    setStorageItem('filters', jsonEncode({}));
    setFilters({});
  };

  return (
    <>
      {!error && (
        <>
          <Nameplate
            skeleton={loading}
            title="You are booking through:"
            heading={agency.companyName}
            subHeading={agency.city}
            logo={agency.logo}
          />
          {!loading && (
            <Container maxWidth="md">
              <PropertyFilterForm
                initialValues={filters}
                onClear={handleFilterClear}
                onSubmit={handleFilterSubmit}
              />
              <PropertyList
                items={filterProperties(properties, filters)}
                loading={loading}
                renderItem={({id, photo, ...rest}) => (
                  <PropertyItem
                    {...rest}
                    footerContent={<PropertyItemFooter />}
                    photo={photo || placeholderImg}
                    onClick={() => {
                      window.history.pushState(
                        {
                          lastScrollPos:
                            document.documentElement.scrollTop ||
                            document.body.scrollTop,
                        },
                        null
                      );
                      navigate(`/${id}`);
                    }}
                    skeleton={loading}
                  />
                )}
              />
            </Container>
          )}
        </>
      )}
      {error && <ListingErrorMessage />}
      <Footer />
    </>
  );
}

export default ListingFeature;
