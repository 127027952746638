import React from 'react';
import PropTypes from 'prop-types';
import {
  Animate,
  Box,
  Button,
  Container,
  Paper,
  Heading,
  Text,
} from '@tenancy.nz/design-system';

function BookingSuccess({
  address,
  loginUrl,
  signUpUrl,
  viewingsUrl,
  subSectionHeading,
}) {
  return (
    <Animate>
      <Box marginTop="70px">
        <Container maxWidth="sm">
          <Paper>
            <Box margin="30px">
              <Heading as="h1" align="center" color="inherit">
                Booking Successful
              </Heading>
              <Text
                align="center"
                color="inherit"
                variant="subtitle1"
                paragraph>
                Your viewing time {(address && `for `) || ``}{' '}
                <strong>{address}</strong> has been submitted. You will receive
                an email shortly with your viewing details.
              </Text>
            </Box>
          </Paper>
          <Box marginTop="30px">
            {subSectionHeading && (
              <Text
                variant="subtite1"
                weight="600"
                align="center"
                color="inherit"
                paragraph>
                {subSectionHeading}
              </Text>
            )}
            {viewingsUrl && (
              <Box width="100%" maxWidth="350px" margin="20px auto">
                <Button
                  as="a"
                  href={viewingsUrl}
                  color="secondary"
                  elevation
                  fill>
                  Manage Your Bookings
                </Button>
              </Box>
            )}
            {loginUrl && (
              <Box width="100%" maxWidth="350px" margin="20px auto">
                <Button as="a" href={loginUrl} color="primary" elevation fill>
                  Login
                </Button>
              </Box>
            )}
            {signUpUrl && (
              <Box width="100%" maxWidth="350px" margin="20px auto">
                <Button as="a" href={signUpUrl} color="tertiary" elevation fill>
                  Create Profile
                </Button>
              </Box>
            )}
          </Box>
        </Container>
      </Box>
    </Animate>
  );
}

BookingSuccess.propTypes = {
  address: PropTypes.string,
  loginUrl: PropTypes.string,
  signUpUrl: PropTypes.string,
  viewingsUrl: PropTypes.string,
  subSectionHeading: PropTypes.string,
};

BookingSuccess.defaultProps = {
  address: undefined,
  loginUrl: undefined,
  signUpUrl: undefined,
  viewingsUrl: undefined,
  subSectionHeading: undefined,
};

export default BookingSuccess;
