import {isEmpty, isString} from './boolean';

/**
 * Show 'N/A' if provided var is empty
 *
 * @param {any} val
 *
 * @return {string}
 */
export const naIfEmpty = (val, fallback) =>
  !isEmpty(val) ? val : fallback || 'N/A';

/**
 * Removes the HTML tags from given string
 *
 * @param {string} str
 *
 * @return {string}
 */
export const stripTags = str => str.replace(/(<([^>]+)>)/gi, '');

/**
 * Generates a random string
 *
 * @return {string}
 */
export const randomChars = () => Math.random().toString(16).substr(2, 8);

/**
 * Get uppercased first char of provided string
 *
 * @param {string}
 *
 * @return {string}
 */
export const ucFirstChar = str => {
  if (!isString(str)) {
    return '';
  }
  return str.charAt(0).toUpperCase();
};

/**
 * Make first char of string uppercase
 *
 * @param {string}
 *
 * @return {string}
 */
export const ucFirst = str => {
  if (!isString(str)) {
    return '';
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const boolNaIfNull = (value, fallback = 'N/A') => {
  if (value === false || value === 0 || value === '0') {
    return 'No';
  }
  if (value === true || value === 1 || value === '1') {
    return 'Yes';
  }
  if (!value) {
    return fallback;
  }
  return value;
};

/**
 * Make a string lowercase
 *
 * @param {string}
 *
 * @return {string}
 */
export const strToLower = str => {
  if (isEmpty(str)) {
    return '';
  }
  if (isString(str)) {
    return str.toLowerCase();
  }
  return str;
};

/**
 * Converts provided value to string
 *
 * @param {mixed}
 *
 * @return {string}
 */
export const toString = val => String(val);
