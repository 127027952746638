import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {uid} from 'react-uid';
import {motion, AnimateSharedLayout, useAnimation} from 'framer-motion';
import {Grid, Heading} from '@tenancy.nz/design-system';

function PropertyList({items, loading, renderItem}) {
  const controls = useAnimation();

  useEffect(() => {
    const animate = async () => {
      await controls.start('hidden');
      controls.start('show');
    };
    animate();
  }, [items, controls]);

  const containerAnimation = {
    hidden: {opacity: 0},
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemAnimation = {
    hidden: {scale: 0.9, translateY: -20, opacity: 0},
    show: {scale: 1, translateY: 1, opacity: 1},
  };

  return (
    <AnimateSharedLayout>
      <motion.div
        variants={containerAnimation}
        initial="hidden"
        animate={controls}>
        <Grid container spacing={6} alignItems="stretch">
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}>
            Properties: {items.length}
          </Grid>
          {!loading &&
            items.map(item => (
              <Grid item xs={12} md={6} key={uid(item)}>
                <motion.div variants={itemAnimation} style={{height: '100%'}}>
                  {renderItem(item)}
                </motion.div>
              </Grid>
            ))}
          {!loading && items.length === 0 && (
            <Grid item xs={12}>
              <motion.div variants={itemAnimation}>
                <Heading as="h2" color="primary" italic>
                  No properties found matching your search criteria.
                </Heading>
              </motion.div>
            </Grid>
          )}
        </Grid>
      </motion.div>
    </AnimateSharedLayout>
  );
}

PropertyList.propTypes = {
  /**
   *
   */
  items: PropTypes.array,

  /**
   *
   */
  loading: PropTypes.bool,

  /**
   *
   */
  renderItem: PropTypes.func.isRequired,
};

PropertyList.defaultProps = {
  items: [],
  loading: false,
};

export default PropertyList;
