import logoimg from '../../assets/logo-v2.png';
import logoimgMobile from '../../assets/logo-mobile.png';
import notFoundImg from '../../assets/404-illustration.png';

/**
 * A data uri to create blank image
 *
 * @type {string}
 */
export const BLANK_IMG_SRC =
  'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';

/**
 * Default app logo. Typically displayed in header
 *
 * @type {string}
 */
export const DEFAULT_LOGO = logoimg;

/**
 * Default app logo for mobile devices. Typically displayed in header
 *
 * @type {string}
 */
export const DEFAULT_LOGO_MOBILE = logoimgMobile;

/**
 * Default image to display on 404 page
 *
 * @type {string}
 */
export const DEFAULT_404_IMG = notFoundImg;

/**
 * Default app logo alt text if logo image link is broken
 *
 * @type {string}
 */
export const DEFAULT_LOGO_ALT = 'BookMe';
